import useApiClient from './api-client'
import type { BranchDetailData, FbranchArrivaltimeInfo, SregionSaleRegionsListInfo } from '@/types/api'
import type { GetBranchArrivalTimesParams, GetBranchDetailParams } from '@/types/branch'

export const getBranch = async (branchId: number | string, params?: GetBranchDetailParams) => {
  const api = useApiClient<BranchDetailData>()
  const result = await api(`/branch/${branchId}`, { query: params })

  return result
}

export const getBranchArrivalTimes = async (branchId: number | string, params?: GetBranchArrivalTimesParams) => {
  const api = useApiClient<FbranchArrivaltimeInfo>()
  const result = await api(`/branch/${branchId}/arrivaltimes`, { query: params })

  return result
}

export const getBranchSaleRegions = async (bid: string) => {
  const api = useApiClient<SregionSaleRegionsListInfo[]>()
  const result = await api(`/branch/${bid}/sale-regions`)
  return result
}
