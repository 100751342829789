import { defineStore } from 'pinia'

import type { MemberListCollectionData, NicedayProductListData, NicedayRegionPopularData, SregionSaleRegionsListInfo } from '@/types/api'
import type { UiProductInfo } from '@/types/product'
import { getProductSaleRegions } from '@/service/product'

type ListKey = 'popularProductGroup' | 'newestProductGroup' | 'searchProduct' | 'branchRelatedProduct' | 'branchHotProduct' | 'collections'
type ProductListData = NicedayProductListData & NicedayRegionPopularData & MemberListCollectionData

export const useProductStore = defineStore('product', () => {
  // 因 product info 的資料會與 list 資料不一樣，所以需要另外存
  const productInfo = ref<UiProductInfo>({})
  const saleRegions = ref<SregionSaleRegionsListInfo[]>([])

  const productMap = reactive<Record<number, ProductListData>>({})
  const listIds = reactive<Partial<Record<ListKey, number[]>>>({})

  const setProductInfo = (product: UiProductInfo) => {
    productInfo.value = product.pid === productInfo.value.pid ? { ...productInfo.value, ...product } : product
  }

  const setToProductMap = (product: ProductListData) => {
    const pid = product.product_id

    if (pid) {
      productMap[pid] = product
    }
  }

  const handleProducts = (key: ListKey, products?: Nullable<ProductListData[]>) => {
    if (!Array.isArray(products)) return

    listIds[key] = products.map(product => product.product_id!)
    products.forEach(product => {
      setToProductMap(product)
    })
  }

  const setValueToMapByKey = (id: number, key: keyof (ProductListData | UiProductInfo), newValueOrUpdater: any | ((prevState: any) => any)) => {
    if (!productMap[id]) return

    const newValue = typeof newValueOrUpdater === 'function' ? newValueOrUpdater(productMap[id][key]) : newValueOrUpdater

    productMap[id][key] = newValue
    productInfo.value[key] = newValue
  }

  const fetchSaleRegionsById = async (pid: string) => {
    const { data } = await getProductSaleRegions(pid)
    saleRegions.value = data
    return data
  }

  return {
    productInfo,
    productMap,
    saleRegions,
    setProductInfo,
    setToProductMap,
    listIds,
    handleProducts,
    setValueToMapByKey,
    fetchSaleRegionsById,
  }
})
