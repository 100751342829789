import type { Pinia } from 'pinia'
import { useRegionStore } from '@/store/region'
import { useProductStore } from '@/store/product'
import { useBranchStore } from '@/store/branch'

import { supportedLangsByCode, supportedLangsByName } from '@/constants/locales'

type Option = {
  $pinia?: Pinia | null
}

export function useRegion() {
  const route = useRoute()
  const router = useLocaleRouter()

  const regionStore = useRegionStore()
  const regionId = computed(() => regionStore.currentRegionId)

  const changeRegion = (regionId: number, cb?: () => void) => {
    regionStore.currentRegionId = regionId
    router.replace({ path: `/regions/${regionId}`, query: route.query, hash: route.hash })
    if (typeof cb === 'function') {
      cb()
    }
  }

  return { regionId, changeRegion }
}

/**
 * 這個 composable 是在 middleware 中使用，請不要在裡面使用 server side 不允許的，例如 `useRoute`。
 */
export function useRegionChecker() {
  function detectValidLang(currentLangCode: string, option: Option = {}) {
    const regionStore = useRegionStore(option.$pinia)

    const currentRegionSupportedLangs = regionStore.region?.languages || []
    // 因 BE language 中的欄位 `code` 是 FE 的 `name`，故需要先找當前語言的 `name` 是什麼
    const currentLangName = supportedLangsByCode[currentLangCode]?.name
    const isCurrentLangValid = currentRegionSupportedLangs.some(l => l.code === currentLangName)
    const fallbackLang = supportedLangsByName[currentRegionSupportedLangs[0]?.code || '']?.code

    return { isCurrentLangValid, supportedLang: isCurrentLangValid ? currentLangCode : fallbackLang }
  }

  function getSaleRegionInfo({ isToProductPage, routeRegionId }: { isToProductPage: boolean; routeRegionId: number }, option: Option = {}) {
    const productStore = useProductStore(option.$pinia)
    const branchStore = useBranchStore(option.$pinia)
    const saleRegions = isToProductPage ? productStore.saleRegions : branchStore.saleRegions

    const saleRegionIds = saleRegions.map(r => r.region_id)
    const isSaleRegionsEmpty = saleRegions.length === 0
    const isRegionInSale = saleRegionIds.includes(routeRegionId)
    const validSaleRegionId = isRegionInSale ? routeRegionId : saleRegionIds[0]

    return { isSaleRegionsEmpty, isRegionInSale, validSaleRegionId }
  }

  return { detectValidLang, getSaleRegionInfo }
}
