import { defineStore } from 'pinia'

import type { BranchDetailData, SregionSaleRegionsListInfo, NicedayMemberListFavoriteData } from '@/types/api'
import { getBranchSaleRegions } from '@/service/branch'

export const useBranchStore = defineStore('branch', () => {
  const saleRegions = ref<SregionSaleRegionsListInfo[]>([])

  const branchMap = reactive<Record<number, BranchDetailData & NicedayMemberListFavoriteData>>({})

  const setToBranchMap = (branch: BranchDetailData) => {
    const bid = branch.bid

    if (bid) {
      branchMap[bid] = branch
    }
  }

  const setValueToMapByKey = (id: number, key: keyof BranchDetailData, newValueOrUpdater: any | ((prevState: any) => any)) => {
    if (!branchMap[id]) return

    if (typeof newValueOrUpdater === 'function') {
      branchMap[id][key] = newValueOrUpdater(branchMap[id][key])
    } else {
      branchMap[id][key] = newValueOrUpdater
    }
  }

  const fetchSaleRegionsById = async (bid: string) => {
    const { data } = await getBranchSaleRegions(bid)
    saleRegions.value = data
    return data
  }

  return {
    branchMap,
    saleRegions,
    setToBranchMap,
    setValueToMapByKey,
    fetchSaleRegionsById,
  }
})
